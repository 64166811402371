import { Flex, Text, Heading } from '@moonpig/launchpad-components'
import { IconSystemChevronRight } from '@moonpig/launchpad-assets'
import { useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { system as s } from '@moonpig/launchpad-system'
import { breakpointDown, breakpointUp, css } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import styled from 'styled-components'
import { PromoTileCta } from '../../types'
import { SponsoredPill } from '../SponsoredPill/SponsoredPill'

const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 200px;

  ${s({
    borderRadius: 2,
    boxShadow: 1,
    border: '1px solid',
    borderColor: 'colorBorder03',
  })}
`

const StyledImage = styled.img`
  position: relative;
  flex: 1;
  overflow: hidden;
  object-fit: cover;
  border-top-left-radius: 8px;
  ${breakpointUp('lg')} {
    border-bottom-left-radius: 8px;
  }
`

const ContentWrapper = styled(Flex)`
  min-height: 200px;
  flex: 1;

  ${breakpointDown('lg')} {
    min-height: 280px;
  }
`

const sharedCtaStyles = css`
  border-top: 1px solid #e8e8e8;
  ${s({
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    gap: 4,
    p: 6,
    typography: 'typeButtonUnderlined',
    color: 'colorInteractionButton',
  })};
`

const DesktopCta = styled.div`
  ${sharedCtaStyles}

  ${breakpointDown('lg')} {
    display: none;
  }
`

const MobileCta = styled.div`
  ${sharedCtaStyles}

  ${breakpointUp('lg')} {
    display: none;
  }
`

type PromoTileVariantProps = {
  title: string
  description: string
  sponsored: boolean
  image: {
    src: string
    description: string | undefined
  }
  cta: PromoTileCta | undefined
}

export const PromoTileVariant: FC<PromoTileVariantProps> = ({
  title,
  description,
  sponsored,
  image,
  cta,
}) => {
  const { trackGAEventOnce } = useTrackGAEventOnce()
  const trackClick = () =>
    trackGAEventOnce({
      event: 'select_content',
      content_data: {
        content_type: `search | sponsored tile click | ${cta?.title.toLowerCase()}`,
      },
      // For GA3 backward compatibility
      event_data: {
        category: 'search',
        action: 'select sponsored tile',
        label: `${cta?.url} | ${cta?.title.toLowerCase()}`,
        non_interaction: true,
        value: undefined,
      },
      // Web only //
      discount_data: undefined,
      ecommerce: undefined,
      error_data: undefined,
    })

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    trackClick()

    if (cta?.onClick) {
      cta.onClick()
    }
    if (cta?.url && !cta?.onClick) {
      window.location.href = cta?.url
    }
  }

  return (
    <StyledButton
      aria-label="sponsored product tile"
      data-testid="promo-tile-variant-container"
      onClick={handleClick}
    >
      <Flex width="100%" height="100%">
        <StyledImage alt={image.description} src={image.src} />
        <ContentWrapper flexDirection="column" justifyContent="space-between">
          {sponsored && <SponsoredPill />}
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            alignItems="start"
            height="100%"
          >
            <Flex flexDirection="column" alignItems="start" p={6}>
              <Heading level="h4" mb={4} textAlign="left">
                {title}
              </Heading>
              <Text
                typography="typeBodyText"
                color="colorTextAlternate"
                as="p"
                mb={0}
                textAlign="left"
              >
                {description}
              </Text>
            </Flex>
            <DesktopCta>
              {cta?.title}
              <IconSystemChevronRight width="24px" height="24px" />
            </DesktopCta>
          </Flex>
        </ContentWrapper>
      </Flex>
      <MobileCta>
        {cta?.title}
        <IconSystemChevronRight width="24px" height="24px" />
      </MobileCta>
    </StyledButton>
  )
}
