import { setBrowserCookie, getBrowserCookie } from '@moonpig/web-core-cookies'
import { PageTypeEnum } from '../../types'

export const settingCSATTileCookie = (pageType: PageTypeEnum) => {
  const galleryPage = pageType === PageTypeEnum.GALLERY
  const searchPage = pageType === PageTypeEnum.SEARCH || PageTypeEnum.DYNAMIC

  if (galleryPage) {
    const existingCookie = getBrowserCookie('gallery_results_csat_tile')
    if (!existingCookie) {
      setBrowserCookie('gallery_results_csat_tile', 'true', {
        maxAge: 60 * 60 * 24 * 14, // 14 days
      })
    }
  } else if (searchPage) {
    const existingCookie = getBrowserCookie('search_results_csat_tile')
    if (!existingCookie) {
      setBrowserCookie('search_results_csat_tile', 'true', {
        maxAge: 60 * 60 * 24 * 14, // 14 days
      })
    }
  }
}
